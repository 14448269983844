import request from '@/utils/request'
import CrudAppService from '@/framework/CrudAppService'
import {
  ExercisePrescriptionDto,
  CreateOrUpdateExercisePrescriptionDto,
  GetListExercisePrescriptionDto
} from '@/framework/models/ExercisePrescription'
import {Guid} from 'guid-typescript'

export default class ExercisePrescriptionApi extends CrudAppService <ExercisePrescriptionDto,
  ExercisePrescriptionDto, Guid, GetListExercisePrescriptionDto, CreateOrUpdateExercisePrescriptionDto,
  CreateOrUpdateExercisePrescriptionDto> {
  constructor() {
    super('/keep-fit/exercise-prescription');
  }
}

export const evaluationConclusionOptions = [
  {label: '有运动习惯,有健康问题', value: 10},
  {label: '有运动习惯,无健康问题', value: 11},
  {label: '无运动习惯,有健康问题', value: 12},
  {label: '无运动习惯,无健康问题', value: 13}
]

export const bmiOptions = [
  {label: '体重低', value: 10},
  {label: '正常', value: 11},
  {label: '超重,疾病风险增加', value: 12},
  {label: '肥胖,高级病风险', value: 13}
]

export const whrOptions = [
  {label: '低', value: 10},
  {label: '中', value: 11},
  {label: '高', value: 12}
]

export const bodyFatOptions = [
  {label: '极好', value: 10},
  {label: '良好', value: 11},
  {label: '一般', value: 12}
]

export const segmentalMuscleOptions = [
  {label: '均达标', value: 10},
  {label: '上肢未达标', value: 11},
  {label: '下肢未达标', value: 12},
  {label: '躯干未达标', value: 13},
  {label: '上肢和躯干未达标', value: 14},
  {label: '下肢和躯干未达标', value: 15},
  {label: '上肢和下肢未达标', value: 16}
]

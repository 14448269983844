import request from '@/utils/request'
import {Guid} from 'guid-typescript';

const url = '/keep-fit/exercise-prescription-item'

export default class ExercisePrescriptionItemApi {
  public async saveAsync(id: Guid, items: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      request({
        url: `${url}/${id}`,
        method: 'post',
        data: items
      }).then((res) => resolve(res.data)).catch((err) => reject(err));
    })
  }

  public async getItemInfoList(id: Guid): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      request({
        url: `${url}/${id}`,
        method: 'get'
      }).then((res) => resolve(res.data)).catch((err) => reject(err));
    })
  }
}

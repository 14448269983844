import request from '@/utils/request'
import CrudAppService from '@/framework/CrudAppService'
import {MuscleSiteDto, CreateOrUpdateMuscleSiteDto, GetListMuscleSiteDto} from '@/framework/models/MuscleSite'
import {Guid} from 'guid-typescript'

export default class MuscleSiteApi extends CrudAppService <MuscleSiteDto, MuscleSiteDto, Guid,
  GetListMuscleSiteDto, CreateOrUpdateMuscleSiteDto, CreateOrUpdateMuscleSiteDto> {
  constructor() {
    super('/keep-fit/muscle-site');
  }

  public async getAllList(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      request({
        url: `${this.url}/all`,
        method: 'get'
      }).then((res) => resolve(res.data)).catch((err) => reject(err));
    })
  }
}

import {ExtensibleEntityDto} from './ExtensibleEntityDto'
import {PagedAndSortedResultRequestDto} from './PagedAndSortedResultRequestDto'
import {Guid} from 'guid-typescript';

export interface ExercisePrescriptionDto extends ExtensibleEntityDto <Guid> {
  evaluationConclusion: number
  bmi: number
  whr: number
  bodyFat: number
  segmentalMuscle: number
  frequency: Guid,
  frequencyNumber: number
}

export class CreateOrUpdateExercisePrescriptionDto {
  public evaluationConclusion: number | undefined
  public bmi: number | undefined
  public whr: number | undefined
  public bodyFat: number | undefined
  public segmentalMuscle: number | undefined
  public frequency: Guid | undefined
}

export class GetListExercisePrescriptionDto extends PagedAndSortedResultRequestDto {
}

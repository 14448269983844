import request from '@/utils/request'
import CrudAppService from '@/framework/CrudAppService'
import {
  TrainingTypeDto,
  CreateOrUpdateTrainingTypeDto,
  GetListTrainingTypeDto
} from '@/framework/models/TrainingType'
import {Guid} from 'guid-typescript'

export default class TrainingTypeApi extends CrudAppService <TrainingTypeDto, TrainingTypeDto, Guid,
  GetListTrainingTypeDto, CreateOrUpdateTrainingTypeDto, CreateOrUpdateTrainingTypeDto> {
  constructor() {
    super('/keep-fit/training-type');
  }

  public async getAllList(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      request({
        url: `${this.url}/all`,
        method: 'get'
      }).then((res) => resolve(res.data)).catch((err) => reject(err));
    })
  }
}

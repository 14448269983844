import request from '@/utils/request'
import CrudAppService from '@/framework/CrudAppService'
import {
  MotionFrequencyDto,
  CreateOrUpdateMotionFrequencyDto,
  GetListMotionFrequencyDto
} from '@/framework/models/MotionFrequency'
import {Guid} from 'guid-typescript'

import {ListResultDto} from '@/framework/models/ResultDto'

export default class MotionFrequencyApi extends CrudAppService <MotionFrequencyDto, MotionFrequencyDto, Guid,
  GetListMotionFrequencyDto, CreateOrUpdateMotionFrequencyDto, CreateOrUpdateMotionFrequencyDto> {
  constructor() {
    super('/keep-fit/motion-frequency');
  }

  public async getAllList(): Promise<ListResultDto<MotionFrequencyDto>> {
    return new Promise((resolve, reject) => {
      request({
        url: `${this.url}/all`,
        method: 'get'
      }).then((res) => resolve(res.data)).catch((err) => reject(err));
    })
  }
}
